<template>
  <footer id="footer" class="site-footer" :class="worknprosper ? 'bg-landingGrey py-16' : 'py-12'">
    <div class="l-container">
      <div class="lg:hidden text-center mb-10">
        <h3 class="col-heading">Contact Us</h3>
        <ul class="grid gap-2 mb-6 footer-list">
          <li>
            <a href="mailto:hi@myeazipay.net">hi@myeazipay.net</a>
          </li>
          <li>
            <a href="tel:+234 700 033 2947">07000332947 (070003EAZIPAY)</a>
          </li>
        </ul>

        <FooterContactForm />
      </div>

      <div class="flex flex-col lg:flex-row-reverse lg:justify-between gap-10">
        <div
          class="footer-cols lg:w-full flex flex-wrap justify-center lg:justify-start lg:flex-nowrap gap-y-8 lg:gap-10"
        >
          <div class="footer-col w-1/2 lg:w-1/4 products text-center lg:text-left">
            <h3 class="col-heading">
              {{ worknprosper ? "Company" : "Products" }}
            </h3>
            <ul v-if="!worknprosper" class="footer-list">
              <li>
                <a href="/individual"> Individual </a>
              </li>
              <li>
                <a href="/business">Businesses</a>
              </li>
              <!-- <li>
                <a href="/business">Request Demo</a>
              </li> -->
              <li>
                <a href="/register">Pricing</a>
              </li>
            </ul>
            <ul v-else class="footer-list">
              <li @click="$emit('scroll', 'home')">
                <!-- <a href="/wnpLanding"> WorknProsper </a> -->
                WorknProsper
              </li>
              <li @click="$emit('scroll', 'faq')">FAQs</li>
            </ul>
          </div>
          <div class="footer-col order-3 lg:order-none w-1/2 lg:w-1/4 legal text-center lg:text-left">
            <h3 class="col-heading">Legal</h3>
            <ul class="footer-list">
              <li>
                <a :href="worknprosper ? '/wnp/privacy-policy' : '/privacy-policy'">Privacy Policy</a>
              </li>
              <li>
                <a :href="worknprosper ? '/wnp/terms-of-use' : '/terms-of-use'"> Terms of Use </a>
              </li>
            </ul>
          </div>

          <div class="footer-col order-3 lg:order-none w-1/2 lg:w-1/4 legal text-center lg:text-left">
            <h3 class="col-heading">Resources</h3>
            <ul class="footer-list">
              <li>
                <a href="#faq">FAQs</a>
              </li>
              <li>
                <a target="_blank" href="https://blog.eazipay.ng/"> Blog </a>
              </li>
              <li>
                <a> Career </a>
              </li>
              <li>
                <a href="#testimonials"> Customer Stories </a>
              </li>
            </ul>
          </div>

          <div class="footer-col lg:w-2/4 contact-us hidden lg:block text-left">
            <h3 class="col-heading">Contact Us</h3>
            <ul class="grid gap-2 mb-6 footer-list">
              <li>
                <a href="mailto:hi@myeazipay.net">hi@myeazipay.net</a>
                <!-- <a  href="mailto:wnp@myeazipay.ng">wnp@myeazipay.ng</a> -->
              </li>
              <li>
                <a href="tel:+2347000332947">07000332947 (070003EAZIPAY)</a>
              </li>
            </ul>
            <!-- <ul v-else class="grid gap-2 mb-6 footer-list">
              <li>
                <a href="mailto:wnp@myeazipay.ng">wnp@myeazipay.ng</a>
              </li>
              <li>
                <a href="tel:+234 816 878 9518">+234 816 878 9518</a>
              </li>
            </ul> -->

            <FooterContactForm />
          </div>
        </div>

        <div class="text-center lg:flex-row lg:text-left lg:w-3/12 flex-shrink-0">
          <div class="mb-6">
            <Logo class="inline-block" />
          </div>
          <p class="mb-2">Copyright © 2024 Eazipay.</p>
          <p class="mb-6">All rights reserved</p>
          <div class="social-links inline-flex gap-3 justify-center">
            <a
              v-for="(link, index) in socialLinks"
              :key="`social-link-${index}`"
              :href="link.href"
              :title="link.title"
              target="_blank"
              rel="noopener noreferrer"
              class="social-link"
            >
              <component :is="link.icon" />
            </a>
          </div>

          <div class="flex flex-col gap-3 md:gap-6 mt-7">
            <p class="font-bold text-primary-deep">Download the Eazipay Mobile App</p>

            <div class="hidden md:flex items-center justify-center md:justify-start gap-5">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/eazipay-business/id6446281426"
              >
                <img class="w-32" src="@/www/pages/landing/assets/ios-download.png" alt=""
              /></a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.myeazipay.business"
                ><img class="w-32" src="@/www/pages/landing/assets/android-download.png" alt=""
              /></a>
            </div>

            <div class="md:hidden flex items-center justify-center md:justify-start gap-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/eazipay-business/id6446281426"
              >
                <img class="w-32" src="@/www/components/assets/ios-download-mobile.png" alt=""
              /></a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.myeazipay.business"
                ><img class="w-32" src="@/www/components/assets/android-download-mobile.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import Logo from "./Logo.vue";
import FacebookIcon from "./social-icons/Facebook.vue";
import TwitterIcon from "./social-icons/Twitter.vue";
import LinkedinIcon from "./social-icons/Linkedin.vue";
import InstagramIcon from "./social-icons/Instagram.vue";
import FooterContactForm from "./FooterContactForm.vue";
import { ref } from "vue";

const worknprosper = ref(false);
const socialLinks = [
  {
    title: "Instagram",
    href: "https://www.instagram.com/myeazipay?igsh=Mmx5c2lsaHdxNjlm",
    icon: InstagramIcon,
  },
  {
    title: "Twitter",
    href: "https://www.twitter.com/myeazipay",
    icon: TwitterIcon,
  },
  {
    title: "Linkedin",
    href: "https://www.linkedin.com/company/myeazipay/",
    icon: LinkedinIcon,
  },
  {
    title: "Facebook",
    href: "https://web.facebook.com/myeazipay",
    icon: FacebookIcon,
  },
];
</script>

<style lang="scss" scoped>
.site-footer {
  border-top: 1px solid #eaeaea;

  // .footer-cols {
  // }

  .col-heading {
    font-weight: bold;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 24px;
  }

  .footer-list {
    display: grid;
    gap: 12px;

    li {
      color: #7c7c7c;
      font-size: 16px;
      line-height: 24px;
    }

    a:hover {
      color: #ea4e4b;
    }
  }
}
</style>
